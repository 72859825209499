import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Configuring CORS";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "configuring-cors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#configuring-cors",
        "aria-label": "configuring cors permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring CORS`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#allowing-any-origin"
        }}>{`Allowing any origin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#allowing-specific-origins"
        }}>{`Allowing specific origins`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#applying-different-policies-for-different-origins"
        }}>{`Applying different policies for different origins`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#applying-a-policy-to-the-specific-paths"
        }}>{`Applying a policy to the specific paths`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configuring-cors-via-annotation"
        }}>{`Configuring CORS via annotation`}</a></li>
    </ul>
    <p>{`Armeria provides a way to configure Cross-origin resource sharing (CORS) policy for specific origins or
any origin via `}<a parentName="p" {...{
        "href": "type://CorsServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/cors/CorsServiceBuilder.html"
      }}>{`type://CorsServiceBuilder`}</a>{`. For more information about CORS,
visit `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Cross-origin_resource_sharing"
      }}>{`Wikipedia's CORS page`}</a>{`.`}</p>
    <h2 {...{
      "id": "allowing-any-origin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#allowing-any-origin",
        "aria-label": "allowing any origin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Allowing any origin`}</h2>
    <p>{`To configure CORS Service allowing any origin (*), use `}<a parentName="p" {...{
        "href": "type://CorsService#builderForAnyOrigin():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/cors/CorsService.html#builderForAnyOrigin()"
      }}>{`type://CorsService#builderForAnyOrigin()`}</a>{`, e.g.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpMethod;
import com.linecorp.armeria.server.HttpService;
import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.server.cors.CorsService;

HttpService myService = (ctx, req) -> ...;
Function<? super HttpService, CorsService> corsService =
        CorsService.builderForAnyOrigin()
                   .allowCredentials()
                   .allowRequestMethods(HttpMethod.POST, HttpMethod.GET)
                   .allowRequestHeaders("allow_request_header")
                   .exposeHeaders("expose_header_1", "expose_header_2")
                   .preflightResponseHeader("x-preflight-cors", "Hello CORS")
                   .newDecorator();

ServerBuilder sb = Server.builder()
                         .service("/message", myService.decorate(corsService));
`}</code></pre>
    <h2 {...{
      "id": "allowing-specific-origins",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#allowing-specific-origins",
        "aria-label": "allowing specific origins permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Allowing specific origins`}</h2>
    <p>{`To configure CORS Service allowing specific origins, use `}<inlineCode parentName="p">{`CorsService.builder()`}</inlineCode>{`, e.g.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HttpService myService = (ctx, req) -> ...;
Function<? super HttpService, CorsService> corsService =
        CorsService.builder("http://example.com")
                   .allowCredentials()
                   .allowNullOrigin() // 'Origin: null' will be accepted.
                   .allowRequestMethods(HttpMethod.POST, HttpMethod.GET)
                   .allowRequestHeaders("allow_request_header")
                   .exposeHeaders("expose_header_1", "expose_header_2")
                   .preflightResponseHeader("x-preflight-cors", "Hello CORS")
                   .newDecorator();

ServerBuilder sb = Server.builder()
                         .service("/message", myService.decorate(corsService));
`}</code></pre>
    <h2 {...{
      "id": "applying-different-policies-for-different-origins",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#applying-different-policies-for-different-origins",
        "aria-label": "applying different policies for different origins permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Applying different policies for different origins`}</h2>
    <p>{`To configure different policies for different groups of origins, use `}<inlineCode parentName="p">{`andForOrigins()`}</inlineCode>{` or `}<inlineCode parentName="p">{`andForOrigin()`}</inlineCode>{`
method which creates a new `}<a parentName="p" {...{
        "href": "type://ChainedCorsPolicyBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/cors/ChainedCorsPolicyBuilder.html"
      }}>{`type://ChainedCorsPolicyBuilder`}</a>{` with the specific origins.
Call `}<inlineCode parentName="p">{`and()`}</inlineCode>{` to return to `}<a parentName="p" {...{
        "href": "type://CorsServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/cors/CorsServiceBuilder.html"
      }}>{`type://CorsServiceBuilder`}</a>{` once you are done with building a policy, e.g.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HttpService myService = (ctx, req) -> ...;
Function<? super HttpService, CorsService> corsService =
        CorsService.builder("http://example.com")
                   .allowCredentials()
                   .allowNullOrigin() // 'Origin: null' will be accepted.
                   .allowRequestMethods(HttpMethod.POST, HttpMethod.GET)
                   .allowRequestHeaders("allow_request_header")
                   .exposeHeaders("expose_header_1", "expose_header_2")
                   .preflightResponseHeader("x-preflight-cors", "Hello CORS")
                   .maxAge(3600)
                   .andForOrigins("http://example2.com")
                   .allowCredentials()
                   .allowRequestMethods(HttpMethod.GET)
                   .allowRequestHeaders("allow_request_header2")
                   .exposeHeaders("expose_header_3", "expose_header_4")
                   .and()
                   .newDecorator();

ServerBuilder sb = Server.builder()
                         .service("/message", myService.decorate(corsService));
`}</code></pre>
    <p>{`You can also directly add a `}<a parentName="p" {...{
        "href": "type://CorsPolicy:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/cors/CorsPolicy.html"
      }}>{`type://CorsPolicy`}</a>{` created by a `}<a parentName="p" {...{
        "href": "type://CorsPolicyBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/cors/CorsPolicyBuilder.html"
      }}>{`type://CorsPolicyBuilder`}</a>{`, e.g.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.cors.CorsPolicyBuilder;

HttpService myService = (ctx, req) -> ...;
Function<? super HttpService, CorsService> corsService =
        CorsService.builder("http://example.com")
                   .allowCredentials()
                   .allowNullOrigin() // 'Origin: null' will be accepted.
                   .allowRequestMethods(HttpMethod.POST, HttpMethod.GET)
                   .allowRequestHeaders("allow_request_header")
                   .exposeHeaders("expose_header_1", "expose_header_2")
                   .preflightResponseHeader("x-preflight-cors", "Hello CORS")
                   .maxAge(3600)
                   .addPolicy(CorsPolicy.builder("http://example2.com")
                                        .allowCredentials()
                                        .allowRequestMethods(HttpMethod.GET)
                                        .allowRequestHeaders("allow_request_header2")
                                        .exposeHeaders("expose_header_3", "expose_header_4")
                                        .build())
                   .newDecorator();

ServerBuilder sb = Server.builder()
                         .service("/message", myService.decorate(corsService));
`}</code></pre>
    <h2 {...{
      "id": "applying-a-policy-to-the-specific-paths",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#applying-a-policy-to-the-specific-paths",
        "aria-label": "applying a policy to the specific paths permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Applying a policy to the specific paths`}</h2>
    <p>{`To configure a policy to the specific paths, you can use `}<inlineCode parentName="p">{`route()`}</inlineCode>{` methods in the
`}<a parentName="p" {...{
        "href": "type://CorsServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/cors/CorsServiceBuilder.html"
      }}>{`type://CorsServiceBuilder`}</a>{` and `}<a parentName="p" {...{
        "href": "type://ChainedCorsPolicyBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/cors/ChainedCorsPolicyBuilder.html"
      }}>{`type://ChainedCorsPolicyBuilder`}</a>{`. They can help you to adjust the scope that
a policy is applied to, e.g.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HttpService myService = (ctx, req) -> ...;
Function<? super HttpService, CorsService> corsService =
        CorsService.builder("http://example.com")
                   // CORS policy will be applied for the path that starts with '/message/web/api/'.
                   .route("prefix:/message/web/api/")
                   .allowRequestMethods(HttpMethod.POST, HttpMethod.GET)
                   .newDecorator();
ServerBuilder sb = Server.builder()
                         .service("/message", myService.decorate(corsService));
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`Please refer to the `}<a parentName="p" {...{
          "href": "type://Route:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Route.html"
        }}>{`type://Route`}</a>{` in order to learn how to specify a path pattern.`}</p>
    </Tip>
    <h2 {...{
      "id": "configuring-cors-via-annotation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#configuring-cors-via-annotation",
        "aria-label": "configuring cors via annotation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring CORS via annotation`}</h2>
    <p>{`You can also configure CORS for `}<a parentName="p" {...{
        "href": "/docs/server-annotated-service"
      }}>{`Annotated services`}</a>{` using the
`}<a parentName="p" {...{
        "href": "type://@CorsDecorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/decorator/CorsDecorator.html"
      }}>{`type://@CorsDecorator`}</a>{` annotation, e.g.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.annotation.AdditionalHeader;
import com.linecorp.armeria.server.annotation.Get;
import com.linecorp.armeria.server.annotation.decorator.CorsDecorator;

Object annotatedService = new Object() {
    @Get("/get")
    @CorsDecorator(origins = "http://example.com", credentialsAllowed = true,
                   nullOriginAllowed = true, exposedHeaders = "expose_header",
                   allowedRequestMethods = HttpMethod.GET, allowedRequestHeaders = "allow_header",
                   preflightResponseHeaders = {
                       @AdditionalHeader(name = "preflight_header", value = "preflight_value")
                   })
    // In case you want to configure different CORS policies for different origins.
    @CorsDecorator(origins = "http://example2.com", credentialsAllowed = true)
    public HttpResponse get() {
        return HttpResponse.of(HttpStatus.OK);
    }

    @Post("/post")
    // In case you want to allow any origin (*):
    @CorsDecorator(origins = "*", exposedHeaders = "expose_header")
    // You can not add a policy after adding the decorator allowing any origin (*).
    public HttpResponse post() {
        return HttpResponse.of(HttpStatus.OK)
    }
};

Server s = Server.builder()
                 .annotatedService("/example", annotatedService)
                 .build();
`}</code></pre>
    <p>{`You can also use `}<a parentName="p" {...{
        "href": "type://@CorsDecorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/decorator/CorsDecorator.html"
      }}>{`type://@CorsDecorator`}</a>{` at the class level to apply the decorator to all service methods in the class.
Note that the `}<a parentName="p" {...{
        "href": "type://@CorsDecorator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/decorator/CorsDecorator.html"
      }}>{`type://@CorsDecorator`}</a>{` annotation specified at the method level takes precedence over what's specified
at the class level:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// This decorator will be ignored for the path "/post".
@CorsDecorator(origins = "http://example.com", credentialsAllowed = true)
class MyAnnotatedService {
    @Get("/get")
    public HttpResponse get() {
        return HttpResponse.of(HttpStatus.OK);
    }

    @Post("/post")
    @CorsDecorator(origins = "http://example2.com", credentialsAllowed = true)
    public HttpResponse post() {
        return HttpResponse.of(HttpStatus.OK);
    }
}
`}</code></pre>
    <p>{`If you want to allow a CORS policy to the specific paths, you can use `}<inlineCode parentName="p">{`pathPatterns`}</inlineCode>{` property:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// This policy will be applied only to the paths matched by the pattern.
@CorsDecorator(origins = "http://example.com", pathPatterns = "glob:/**/web/api", credentialsAllowed = true)
class MyAnnotatedService {
    ...
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      